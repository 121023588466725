













































import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";

@Component({
  components: {
    Layout,
  },
})
export default class WorkAddress extends Vue {}
